/* newsUpload.scss */

.news-upload-container {
    padding: 30px;
    max-width: 700px;
    margin: 0 auto;
    border: 1px solid #ddd;
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
}

h1 {
    text-align: center;
    color: #690077;
    margin-bottom: 20px;
    font-size: 2rem;
}

.news-upload-form {
    display: flex;
    flex-direction: column;
    gap: 15px; /* Spacing between elements */
}

.label {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
}

.input,
.textarea {
    padding: 12px;
    border: 2px solid #ccc;
    border-radius: 8px;
    transition: border 0.3s ease, box-shadow 0.3s ease;
    color: black;
    background-color: #f9f9f9 !important;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05); /* Inner shadow for input fields */
}

.input:hover,
.input:focus,
.textarea:hover,
.textarea:focus {
    border-color: #690077;
    box-shadow: 0 0 6px rgba(105, 0, 119, 0.3);
}

.textarea {
    min-height: 150px;
}

.fileInput {
    padding: 8px;
    border: 1px dashed #690077;
    border-radius: 8px;
    background-color: #f9f9f9;
    cursor: pointer;
}
.videoPreview {
    margin-top: 10px;

    video.previewVideo {
      width: 400px;
      height: 250px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }
  }
.imagePreview {
    margin: 10px 0;
    display: flex;
    justify-content: center;
}

.previewImage {
    max-width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 2px solid #ccc;
}

.submitButton {
    padding: 12px;
    background-color: #690077;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.submitButton:hover {
    background-color: #55005c;
    transform: scale(1.05); /* Grow slightly on hover */
}

.message {
    margin-top: 15px;
    color: #690077;
    font-size: 1rem;
    text-align: center;
    padding: 10px;
    background-color: #f0e6f6;
    border-radius: 8px;
}
/* Center loader overlay on the screen */
.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .loader {
    width: 80px;
    height: 50px;
    position: relative;
  }
  
  .loader-text {
    position: absolute;
    top: 0;
    padding: 0;
    margin: 0;
    color: #C8B6FF;
    animation: text_713 3.5s ease both infinite;
    font-size: .8rem;
    letter-spacing: 1px;
  }
  
  .load {
    background-color: #9A79FF;
    border-radius: 50px;
    display: block;
    height: 16px;
    width: 16px;
    bottom: 0;
    position: absolute;
    transform: translateX(64px);
    animation: loading_713 3.5s ease both infinite;
  }
  
  .load::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #D1C2FF;
    border-radius: inherit;
    animation: loading2_713 3.5s ease both infinite;
  }
  
  @keyframes text_713 {
    0% { letter-spacing: 1px; transform: translateX(0px); }
    40% { letter-spacing: 2px; transform: translateX(26px); }
    80% { letter-spacing: 1px; transform: translateX(32px); }
    90% { letter-spacing: 2px; transform: translateX(0px); }
    100% { letter-spacing: 1px; transform: translateX(0px); }
  }
  
  @keyframes loading_713 {
    0% { width: 16px; transform: translateX(0px); }
    40% { width: 100%; transform: translateX(0px); }
    80% { width: 16px; transform: translateX(64px); }
    90% { width: 100%; transform: translateX(0px); }
    100% { width: 16px; transform: translateX(0px); }
  }
  
  @keyframes loading2_713 {
    0% { transform: translateX(0px); width: 16px; }
    40% { transform: translateX(0%); width: 80%; }
    80% { width: 100%; transform: translateX(0px); }
    90% { width: 80%; transform: translateX(15px); }
    100% { transform: translateX(0px); width: 16px; }
  }
  .announcements-container {
    max-height: 400px; /* Adjust this value as needed */
    overflow-y: auto; /* This enables vertical scrolling */
    padding: 20px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  .file-cards {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .file-card {
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .no-files {
    color: #888;
    font-size: 1.2em;
    text-align: center;
  }
  
  .delete-button12 {
    background-color: #ff4d4f;  /* Red background color */
    color: white;               /* White text */
    padding: 10px 20px;         /* Some padding for the button */
    border: none;               /* Remove the default border */
    border-radius: 5px;         /* Rounded corners */
    cursor: pointer;           /* Change cursor to pointer to indicate it's clickable */
    font-size: 16px;            /* Font size */
    transition: background-color 0.3s ease, transform 0.3s ease;  /* Smooth transition for hover effect */
  
    &:hover {
      background-color: #ff3333;  /* Darker red on hover */
      transform: scale(1.05);      /* Slightly scale up the button on hover */
    }
  
    &:focus {
      outline: none;  /* Remove default focus outline */
    }
  
    &:active {
      background-color: #e60000;  /* Even darker red when button is pressed */
    }
  }
  
  .delete-button:disabled {
    background-color: #cccccc;  /* Light grey when disabled */
    cursor: not-allowed;        /* Not allowed cursor */
  }
  
