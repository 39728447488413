.customer-container {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    text-align: center;
    max-height: 600px; /* Set a max height for the container */
    overflow-y: auto; /* Make the container scrollable vertically */
    
    h2 {
      font-size: 2rem;
      margin-bottom: 20px;
      color: #333;
    }
  
    .customer-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); 
      gap: 20px;
      justify-content: center;
    }
  
    .customer-card {
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      padding: 15px;
      text-align: center;
      transition: transform 0.3s ease-in-out;
  
      &:hover {
        transform: translateY(-5px);
      }
  
      .customer-image {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
        margin-bottom: 10px;
        border: 3px solid #007bff;
      }
  
      h3 {
        font-size: 1.2rem;
        margin-bottom: 5px;
        color: #333;
      }
  
      p {
        font-size: 0.9rem;
        color: #666;
      }
    }
  }
  