.add-employee-form-container {
  max-width: 700px;
  margin: 0px auto;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  }
}

h2 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #2C3E50;
}

.add-employee-form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .form-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    label {
      margin-bottom: 8px;
      font-weight: bold;
      font-size: 14px;
      color: #34495E;
      width: 30%;
      text-align: left;
    }

    input, select {
      width: 65%;
      padding: 10px 14px;
      border: 1px solid #ccc;
      border-radius: 8px;
      font-size: 16px;
      background-color: #f9f9f9;
      color: #2C3E50;
      transition: all 0.3s ease;

      &:focus {
        outline: none;
        border-color: #690077;
        box-shadow: 0 0 8px rgba(52, 152, 219, 0.3);
      }
    }

    select {
      appearance: none; /* Remove default dropdown appearance */
      background-color: #f9f9f9;
      background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none"%3e%3cpath fill="%23ccc" d="M14.293 7.293a1 1 0 0 0-1.414 0L10 10.586 7.121 7.707a1 1 0 0 0-1.414 1.414l3.536 3.535a1 1 0 0 0 1.414 0l3.535-3.535a1 1 0 0 0 0-1.414z"/%3e%3c/svg%3e');
      background-repeat: no-repeat;
      background-position: right 10px center;
      padding-right: 30px; /* Space for the arrow */
    }

    .profile-preview {
      margin-right: 0px;
      margin-bottom: 10px;
      max-width: 200px;
      height: 200px;
      border-radius: 50%;
      border: 2px solid #690077;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }
  }

  .submit-btn {
    padding: 12px 20px;
    background-color: #690077;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    transition: all 0.3s ease;
    box-shadow: 0 8px 16px rgba(52, 152, 219, 0.3);

    &:hover {
      background-color: #972ba5;
      transform: translateY(-3px);
      box-shadow: 0 12px 24px rgba(41, 128, 185, 0.5);
    }
  }

  input[type="file"] {
    padding: 0;
    font-size: 16px;
    background-color: #ecf0f1;
    border: none;
    border-radius: 6px;
    color: #2C3E50;
    cursor: pointer;

    &::file-selector-button {
      background-color: #690077;
      color: white;
      padding: 10px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #2980b9;
      }
    }
  }
}

@media (max-width: 768px) {
  .add-employee-form-container {
    padding: 20px;
  }

  h2 {
    font-size: 24px;
  }

  .submit-btn {
    font-size: 16px;
    padding: 10px 16px;
  }
}

@media (max-width: 480px) {
  .add-employee-form-container {
    padding: 15px;
  }

  h2 {
    font-size: 20px;
  }

  .submit-btn {
    font-size: 14px;
    padding: 8px 12px;
  }

  .form-group {
    flex-direction: column;
    
    label {
      width: auto;
    }

    input, select {
      width: 100%;
    }
  }
}
.add-role-form-container {
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.add-role-form {
  display: flex;
  flex-direction: column;

  .form-group {
    margin-bottom: 15px;
  }

  label {
    font-weight: bold;
    margin-bottom: 5px;
  }

  input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .submit-btn {
    background: #690077;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;

    &:hover {
      background: #8e2a9b;
    }
  }

  .message {
    margin-top: 10px;
    font-weight: bold;
    color: green;
  }
}