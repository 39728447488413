.feedback-container {
  max-width: 900px;
  margin: auto;
  padding: 20px;
  text-align: center;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }

  .feedback-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); // 3 items per row dynamically
    gap: 15px;
    max-height: 400px;
    overflow-y: auto;
    padding: 10px;
    border-radius: 10px;
    background: #fff;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #007bff;
      border-radius: 10px;
    }
  }

  .feedback-item {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 15px;
    transition: transform 0.3s ease-in-out;
    text-align: left;
    border-left: 5px solid #690077;

    &:hover {
      transform: translateY(-3px);
    }

    h3 {
      font-size: 1.2rem;
      margin-bottom: 5px;
      color: #690077;
    }

    p {
      font-size: 1rem;
      color: #555;
      line-height: 1.4;
    }
  }
}

// Responsive design
@media (max-width: 900px) {
  .feedback-list {
    grid-template-columns: repeat(2, 1fr); // 2 columns on medium screens
  }
}

@media (max-width: 600px) {
  .feedback-list {
    grid-template-columns: repeat(1, 1fr); // 1 column on small screens
  }
}
