/* jobUpload.scss */

.job-container {
    padding: 20px;
    max-width: 800px; /* Reduced maximum width of the container */
    margin: 0 auto;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.job-form {
    display: flex;
    flex-direction: column;
}

.form-label {
    margin: 10px 0 5px;
}

.text-input,
.description-textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: background-color 0.3s, border 0.3s;
    max-width: 100%; /* Ensure the input fields take the full width of the container */
    width: 100%;
}

/* Hover effect */
.text-input:hover,
.description-textarea:hover {
    border-color: #690077;
}

.description-textarea {
    color: black;
    min-height: 80px;
}

.file-upload {
    margin: 10px 0;
}

.file-preview {
    margin: 10px 0;
    padding: 10px;
    border: 1px dashed #ccc;
    border-radius: 4px;
}

.file-preview-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.delete-button {
    background-color: red;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.submit-button {
    padding: 10px;
    background-color: #690077; /* A more vibrant purple */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s; /* Smooth transition for hover effect */
}

/* Hover effect for the submit button */
.submit-btn:hover {
    background-color: #5e228d; /* Darker purple on hover */
}

.feedback-message {
    margin-top: 10px;
    color: #690077;
}

.page-title {
    color: #2C3E50;
}
.history-container {
    max-width: 900px;
    margin: auto;
    padding: 20px;
    background: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
    h2 {
      text-align: center;
      color: #242949;
      margin-bottom: 20px;
    }
  
    .job-cards {
      display: grid;
      grid-template-columns: repeat(2, 1fr); // Two columns per row
      gap: 20px;
  
      @media (max-width: 768px) {
        grid-template-columns: 1fr; // Single column on small screens
      }
    }
  
    .job-card {
      background: white;
      padding: 15px;
      border-radius: 10px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      transition: transform 0.2s ease-in-out;
  
      &:hover {
        transform: translateY(-5px);
      }
  
      h3 {
        color: #242949;
        margin-bottom: 10px;
        font-size: 18px;
      }
  
      p {
        font-size: 14px;
        color: #555;
        margin: 5px 0;
  
        strong {
          color: #333;
        }
      }
  
      a {
        color: #007bff;
        text-decoration: none;
        font-weight: bold;
  
        &:hover {
          text-decoration: underline;
        }
      }
  
      .delete-button {
        display: block;
        width: 100%;
        padding: 8px;
        margin-top: 10px;
        background: #dc3545;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
        transition: background 0.3s ease-in-out;
  
        &:hover {
          background: #c82333;
        }
      }
    }
  }
  
  .history-container {
    max-height: 400px; /* Adjust this value to your layout */
    overflow-y: auto;  /* Enables scrolling if content exceeds max-height */
    padding: 20px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    border-radius: 8px;
    transition: max-height 0.3s ease-in-out; /* Smooth transition */
  }
  
  .job-cards {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .job-card {
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .download-button,
  .delete-button {
    margin-top: 10px;
    padding: 8px 16px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .download-button:hover,
  .delete-button:hover {
    background-color: #0056b3;
  }
  
  .no-jobs {
    color: #888;
    font-size: 1.2em;
    text-align: center;
  }
  