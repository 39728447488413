/* announcementUpload.scss */

.container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.form {
    display: flex;
    flex-direction: column;
}

.label {
    margin: 10px 0 5px;
}

.input,
.textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border 0.3s; /* Transition for hover effect */
}

.input:hover,
.textarea:hover {
    border-color: #690077; /* Change border color on hover */
}

.textarea {
    min-height: 80px;
}

.fileInput {
    margin: 10px 0;
}

.filePreview {
    margin: 10px 0;
    padding: 10px;
    border: 1px dashed #ccc;
    border-radius: 4px;
}

.fileItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.removeButton {
    background-color: red;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.submitButton {
    padding: 10px;
    background-color: #690077;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.message {
    margin-top: 10px;
    color: #690077;
}
.delete-button {
    display: inline-block;
    margin-top: 15px;
    padding: 8px 12px;
    font-size: 14px;
    color: #fff;
    background-color: #dc3545;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #c82333;
    }
  }
  .download-button {
    display: inline-block;
    padding: 8px 16px; /* Reduced padding */
    font-size: 14px; /* Smaller font size */
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    background-color: #3498db; /* Blue background */
    color: white;
    border-radius: 25px; /* Slightly smaller border-radius */
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  
    /* Add a shadow to make the button pop */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
    /* Responsive font size */
    @media (max-width: 600px) {
      font-size: 12px; /* Slightly smaller font for small screens */
    }
  
    &:hover {
      background-color: #2980b9; /* Darker blue on hover */
      transform: translateY(-2px); /* Slight hover animation */
    }
  
    &:active {
      background-color: #1c5983; /* Even darker blue when clicked */
      transform: translateY(2px); /* Button depression effect */
    }
  
    &:focus {
      outline: none; /* Remove the default focus outline */
      box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.5); /* Custom focus outline */
    }
  }
  .announcements-container {
    max-height: 400px; /* Adjust the height based on your layout */
    overflow-y: auto; /* Enables scrolling if content exceeds max-height */
    padding: 20px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  .file-cards {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .file-card {
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .no-files {
    color: #888;
    font-size: 1.2em;
    text-align: center;
  }
  
  .download-button,
  .delete-button {
    margin-top: 10px;
    padding: 8px 16px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .download-button:hover,
  .delete-button:hover {
    background-color: #0056b3;
  }
  