/* upload.scss */

.container {
    padding: 20px;
    max-width: 900px;
    margin: 0 auto;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.form {
    display: flex;
    flex-direction: column;
}

.label {
    
    margin: 10px 0 5px;
}

.input,
.textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: background-color 0.3s, border 0.3s; /* Add transition for smooth effect */
}

/* Hover effect */
.input:hover,
.textarea:hover {
   border-color: #690077; /* Change background color on hover */
    /* Change text color on hover for better contrast */
    border: none; /* Remove border on hover */
}

.textarea {
    color: black;
    min-height: 80px;
}

.fileInput {
    margin: 10px 0;
}

.filePreview {
    margin: 10px 0;
    padding: 10px;
    border: 1px dashed #ccc;
    border-radius: 4px;
}

.fileItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.removeButton {
    background-color: red;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.submitButton {
    padding: 10px;
    background-color: #690077;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.message {
    margin-top: 10px;
    color: #690077;
}
h1{
    color: #2C3E50;
}
select {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    color: #333;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  
    &:focus {
      border-color: #690077; // Blue border when focused
      outline: none;
      box-shadow: 0 0 5px rgba(203, 206, 209, 0.5);
    }
  
    &:hover {
      border-color: #690077;
    }
  }
  
  option {
    padding: 10px;
    font-size: 14px;
    background: #fff;
    color: #333;
  }
  

  .button-container12 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 2px solid #ccc; // Bottom border to separate from content
    width: 100%;
  
    .toggle-button12 {
      flex: 1;
      text-align: center;
      padding: 12px 0;
      font-size: 16px;
      font-weight: 500;
      color: #666;
      background: none;
      border: none;
      cursor: pointer;
      outline: none;
      transition: color 0.3s ease-in-out, border-bottom 0.3s ease-in-out;
  
      &.active {
        color: #000;
        border-bottom: 3px solid #690077; // Active tab indicator
      }
    }
  }
  .announcements-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  
    h2 {
      font-size: 24px;
      color: #333;
      margin-bottom: 20px;
    }
  
    .return-section {
      &.active {
        display: block;
      }
    }
  
    .file-cards {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 20px;
  
      .file-card {
        background-color: #fff;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        cursor: pointer;
        role: "file-card"; /* Accessible role for the card */
        
        &:hover {
          transform: translateY(-10px);
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
        }
  
        h3 {
          font-size: 18px;
          color: #690077;
          margin-bottom: 10px;
        }
  
        p {
          font-size: 14px;
          color: #555;
          margin-bottom: 10px;
  
          strong {
            font-weight: 600;
          }
        }
  
        a {
          display: inline-block;
          margin-top: 10px;
          font-size: 14px;
          color: #007bff;
          text-decoration: none;
          border: 1px solid #007bff;
          padding: 5px 10px;
          border-radius: 5px;
          transition: background-color 0.3s ease;
  
          &:hover {
            background-color: #007bff;
            color: #fff;
          }
        }
  
        .delete-btn {
          display: inline-block;
          margin-top: 15px;
          padding: 8px 12px;
          font-size: 14px;
          color: #fff;
          background-color: #dc3545;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.3s ease;
  
          &:hover {
            background-color: #c82333;
          }
        }
      }
    }
  
    .no-files {
      font-size: 16px;
      color: #888;
      text-align: center;
      margin-top: 20px;
    }
  }
  .announcements-container {
    max-height: 400px; /* Adjust the height as needed */
    overflow-y: auto;  /* Enables vertical scrolling */
    padding: 20px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    border-radius: 8px;
    transition: max-height 0.3s ease-in-out; /* Smooth transition */
  }
  
  .file-cards {
    display: flex;
    flex-direction: column;
    gap: 15px; /* Space between each file card */
  }
  
  .file-card {
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .download-button,
  .delete-btn {
    margin-top: 10px;
    padding: 8px 16px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .download-button:hover,
  .delete-btn:hover {
    background-color: #0056b3;
  }
  
  .no-files {
    color: #888;
    font-size: 1.2em;
    text-align: center;
  }
  