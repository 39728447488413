.analytics-container {
  padding: 20px;
  text-align: center;

  .counts-row {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    margin-left: 20px;

    .count-box {
      background-color: #eae8ff;
      padding: 20px;
      border-radius: 20px;
      width: 30%;
      text-align: center;
      margin-left: 20px;

      h3 {
        margin-bottom: 5px;
        color: #333;
      }
      p {
        font-size: 24px;
        font-weight: bold;
        color: #333;
      }
    }
  }

  .buttons-row {
    margin-top: 50px;
    flex-direction: column; /* Stack buttons vertically */
    align-items: center;
    gap: 15px; /* Adds space between buttons */

    button {
      padding: 10px 20px;
      margin: 0 10px;
      border: none;
      border-radius: 4px;
      background-color: #5c6bc0;
      color: #fff;
      cursor: pointer;
      width: 200px; /* Optional: set a consistent width for buttons */

      &.active {
        background-color: #690077;
        font-weight: bold;
      }

      &:hover {
        background-color: #3949ab;
      }

      &.view-profile-button {
        background-color: #2196f3; /* Blue color for View Profile button */
        &:hover {
          background-color: #1976d2; /* Darker blue on hover */
        }
      }
    }
  }

  .list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow-y: auto; /* Make it scrollable */
    height: 400px; /* Limit the height */
    width: 100%; /* Ensure the list container takes full width */
    
    .user-card {
      display: flex;
      align-items: center;
      justify-content: space-between; /* Align name and button side by side */
      background-color: #f9f9f9;
      padding: 10px;
      border-radius: 8px;
      margin-bottom: 10px;
      width: 80%;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

      .user-image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 10px;
      }

      .user-info {
        flex: 1;
        display: flex;
        flex-direction: row; /* Align items in a row */
        justify-content: space-between; /* Add spacing between elements */
        align-items: center; /* Align items vertically */
        margin-right: 10px;

        h4 {
          margin: 0;
          font-size: 18px;
          color: #333;
          margin-right: 20px; /* Add space between name and buttons */
        }

        .actions {
          display: flex;
          align-items: center;
          gap: 15px; /* Space between buttons and dropdown */
        }

        .approve-button,
        .decline-button,
        .view-profile-button {
          padding: 5px 15px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          color: #fff;
          font-weight: bold;
          transition: background-color 0.3s ease;

          &.approve-button {
            background-color: #4caf50; /* Green for approve */
          }

          &.decline-button {
            background-color: #f44336; /* Red for decline */
          }

          &.view-profile-button {
            background-color: #2196f3; /* Blue for View Profile */
          }

          &:hover {
            opacity: 0.9;
          }
        }

        .status-dropdown {
          width: auto; /* Adjust width to fit content */
          max-width: 250px;
          margin: 0;
        }
      }
    }
  }
}

/* Style for the popup overlay */
.popup {
  position: fixed; /* Fixed position to cover the entire screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex; /* Use flexbox to center the popup */
  justify-content: center; /* Align horizontally */
  align-items: center; /* Align vertically */
  z-index: 9999; /* Ensure it appears above other content */
}

/* Style for the popup content */
.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 60%; /* Adjust the width as needed */
  max-width: 500px; /* Limit the maximum width */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  position: relative; /* To position the close button inside */
}

/* Close button in the top-right corner of the popup */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: #333;
  cursor: pointer;
}

.close-button:hover {
  color: #ff0000; /* Change color on hover for better interaction */
}

.status-dropdown {
  position: relative;
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
  font-family: 'Arial', sans-serif;
  
  .status-select {
    width: 100%;
    padding: 12px 18px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    font-size: 16px;
    color: #333;
    appearance: none; /* Removes default dropdown styling */
    cursor: pointer;
    transition: all 0.3s ease;

    &:focus {
      outline: none;
      border-color: #3498db;
      box-shadow: 0 0 5px rgba(52, 152, 219, 0.2);
    }

    option {
      padding: 10px;
      background-color: #fff;
      color: #333;
      font-size: 16px;
    }

    // Styling for different status options
    option[value="pending"] {
      color: #f39c12;
    }

    option[value="approved"] {
      color: #2ecc71;
    }

    option[value="suspended"] {
      color: #e74c3c;
    }

    option[value="declined"] {
      color: #95a5a6;
    }

    &:hover {
      background-color: #e2e6ea;
    }
  }

  // Custom arrow for the dropdown
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 15px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #333;
    transform: translateY(-50%);
  }

  // Styling for when the dropdown is focused
  .status-select:focus::after {
    border-top-color: #3498db;
  }
}

// For the select container's hover effect
.status-dropdown:hover .status-select {
  border-color: #3498db;
  box-shadow: 0 0 8px rgba(52, 152, 219, 0.2);
}
